(($) => {
  const gallery = document.querySelector('.gallery-hero');

  let i = 0;
  let n = 1;
  const limit = 4;

  setInterval(() => {
    gallery.classList.remove(`active-${i+1}`);
    i++;
    n--;
    if (i >= limit) i = 0;
    if (n == 0) n = limit;
    gallery.classList.add(`active-${i+1}`);
    if (i+1 === 1) {
      gallery.classList.add("active-reset");
      setTimeout(() => {
        gallery.classList.remove("active-reset");
      },3000);
    }
  },6000);

})(jQuery)